<template>
  <div class="modal is-active is-info">
    <div class="modal-card">
      <div class="modal-card-body">
        <div class="columns is-mobile">
          <div class="column">
            <p class="title is-5 has-text-danger">Sanity check</p>
          </div>
        </div>
      </div>
      <hr class="hr is-marginless" />
      <div class="modal-card-body">
        <div class="content">
          <p>Are you sure you want to do this?</p>
          <p>
            Please type the word
            <code>confirm</code> into the box to confirm this action.
          </p>
        </div>
        <div class="field">
          <input
            v-model="confirmation"
            type="text"
            class="input is-medium is-inline"
            placeholder="confirm"
            :class="{ 'is-success': confirmation === 'confirm' }"
          />
        </div>
      </div>
      <hr class="hr is-marginless" />
      <div class="modal-card-body">
        <div class="columns has-text-right">
          <div class="column">
            <span class="button is-text" @click="close">Close</span>
          </div>
          <div class="column is-narrow">
            <button
              :disabled="loading"
              class="button is-soft is-rounded"
              :class="{ 'is-loading': loading }"
              @click="confirm"
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalConfirm',
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  data: () => ({
    confirmation: ''
  }),
  mounted() {
    this.$store.commit('background/toggle')
  },
  destroyed() {
    this.$store.commit('background/toggle')
  },
  methods: {
    close() {
      this.$emit('close')
    },
    confirm() {
      if (this.confirmation === 'confirm') {
        this.$emit('confirm')
      }
    }
  }
}
</script>
